input[valid="false"] {
    border: 2px solid #af2317;
  }

  .error-border {
    border: 2px solid #af2317;
    height: 54px;
    font-size: 18px;
    padding: 15px 45px 15px 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    outline: 0px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-image: none;
    width: 50%;
  }